<template>
  <div class="w-full video-background__wrapper">

    <div class="video-background">
      <div class="video-foreground">
        <youtube class="w-full video__wrapper" video-id="2DJQqOMaRvk" :player-vars="playerVars" ref="youtube"></youtube>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['video-id'],
  data () {
    return {
      playerVars: {
        autoplay: 1,
        controls: 1,
        showinfo: 0,
        rel: 0,
        // end: 10,
        loop: 1,
        modestbranding: 1
      }
    }
  },
  methods: {
    playVideo() {
      this.$refs.youtube.player.playVideo()
    }
  },
  mounted () {
    // this.playVideo()
  }
}
</script>

<style lang="scss">
header{
  padding-top: 60px;
  // height: 80vh;
  position: relative;
}
.video-background__wrapper{
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  overflow: hidden;
  
  .screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    margin: auto;

    opacity: 0;
    transition: opacity .5s;

    &.active {
      opacity: 1;
    }
  }
}

// Fullscreen background video
.video-background {
  background: #000;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
  height: 100%;
  overflow: hidden;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
</style>
