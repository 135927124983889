import Vue from 'vue'
import Components from './components'
import './app.scss'
import vueyoutube from './plugins/vueyoutube'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  delimiters: ['${', '}']
})
